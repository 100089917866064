/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-loops/no-loops */
import { StoreApi, UseBoundStore } from "zustand";
import { addDays } from "date-fns";

type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;

  store.use = {};
  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store(s => s[k as keyof typeof s]);
  }

  return store;
};

// calculate Orthodox Easter using Meeus Julian algorithm
function calculateOrthodoxEaster(year: number): Date {
  const a = year % 4;
  const b = year % 7;
  const c = year % 19;
  const d = (19 * c + 15) % 30;
  const e = (2 * a + 4 * b - d + 34) % 7;
  const month = Math.floor((d + e + 114) / 31);
  const day = ((d + e + 114) % 31) + 1;

  // convert to Gregorian calendar (13 days difference for 21st century)
  const julianDate = new Date(year, month - 1, day);

  return addDays(julianDate, 13);
}

// get all Easter-related holidays for a given year
function getEasterHolidays(year: number): Array<{ month: number; day: number }> {
  const easterDate = calculateOrthodoxEaster(year);
  const holidays = [];

  // add Easter Friday through Monday
  for (let i = -2; i <= 1; i++) {
    const date = addDays(easterDate, i);

    holidays.push({
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  // calculate Pentecost (50 days after Easter)
  const pentecostDate = addDays(easterDate, 49);
  const secondPentecostDate = addDays(pentecostDate, 1);

  holidays.push({
    month: pentecostDate.getMonth() + 1,
    day: pentecostDate.getDate(),
  });

  holidays.push({
    month: secondPentecostDate.getMonth() + 1,
    day: secondPentecostDate.getDate(),
  });

  return holidays;
}

// fixed holidays that don't change year to year
const FIXED_HOLIDAYS = [
  { month: 1, day: 1 }, // new Year's Day
  { month: 1, day: 2 }, // new Year's Second Day
  { month: 1, day: 6 }, // epiphany
  { month: 1, day: 7 }, // st. John the Baptist
  { month: 1, day: 24 }, // union of Romanian Principalities Day
  { month: 5, day: 1 }, // labor Day
  { month: 6, day: 1 }, // children's Day
  { month: 8, day: 15 }, // assumption of Mary
  { month: 11, day: 30 }, // st. Andrew's Day
  { month: 12, day: 25 },
  { month: 12, day: 26 },
  { month: 12, day: 27 },
  { month: 12, day: 28 },
  { month: 12, day: 29 },
  { month: 12, day: 30 },
  { month: 12, day: 31 },
];

export const isHolidayDate = (date: Date): boolean => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // check fixed holidays
  const isFixedHoliday = FIXED_HOLIDAYS.some(holiday => holiday.month === month && holiday.day === day);

  if (isFixedHoliday) {
    return true;
  }

  // check Easter-related holidays (Easter + Pentecost)
  const movableHolidays = getEasterHolidays(year);

  return movableHolidays.some(holiday => holiday.month === month && holiday.day === day);
};

export const isDateInAvailability = (date: Date, availability: ApiAvailability[]) => {
  // get day of week (0-6, where 0 is Sunday)
  const dayOfWeek = date.getDay();

  // convert to availability format where 0 is Monday and 4 is Friday
  const availabilityDay = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  // check if this day exists in availability
  return availability?.some((slot: ApiAvailability) => slot.weekday === availabilityDay);
};
