/* eslint-disable import/no-duplicates */
/* eslint-disable no-loops/no-loops */
import { FC, useState, useMemo, useEffect } from "react";
import { Typography } from "@/components/typography";
import { Button } from "@/components/button/Button";
import { Link } from "@tanstack/react-router";
import { TZDate } from "@date-fns/tz";
import { LuCalendar, LuCircleGauge, LuX } from "react-icons/lu";

import { Calendar } from "@/components/calendar";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/avatar";
import { useGetOfferByProcessId } from "@/core/hooks/candidate/useGetOffer";
import Loading from "@/components/loading";

import TurndownService from "turndown";
import ReactMarkdown from "react-markdown";

import { Route } from "@/routes/_dashboard-layout/offers/$id";
import { useGetProcessStatus, ProcessStatus } from "@/core/hooks/useGetProcessStatus";
import { useAcceptOffer } from "@/core/hooks/candidate/mutations/useAcceptOffer";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/popover";
import { isHolidayDate } from "@/core/utils";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";
import { Checkbox } from "@/components/checkbox";
import { Label } from "@/components/label";
import { useRejectOffer } from "@/core/hooks/candidate/mutations/useRejectOffer";
import { useAcceptOfferFastTrack } from "@/core/hooks/candidate/mutations/useAcceptOfferFastTrack";
import { format } from "date-fns";
import { ro } from "date-fns/locale";

import { useGetEvents } from "@/core/hooks/company/useGetEvents";
import { useGetAvailability } from "@/core/hooks/useGetAvailability";
import { generateAvailableTimeSlots, getDurationInMinutes } from "@/libs/utils/timeSlots";
import { cn } from "@/libs/utils/cn";
import { InterviewLocation, useProposeInterviewTime } from "@/core/hooks/mutations/useProposeInterviewTime";
import { toast } from "sonner";
import { getAngularToken } from "@/core/auth/utils";
import { useGetAccount } from "@/core/hooks/useGetAccount";
import { useQueryClient } from "@tanstack/react-query";
import { useRejectProposedTime } from "@/core/hooks/mutations/useRejectProposedTimes";
import { useAcceptProposedTime } from "@/core/hooks/mutations/useAcceptProposedTime";
import { Dialog, DialogTitle, DialogHeader, DialogContent } from "@/components/dialog";
import { useCancelEvent } from "@/core/hooks/mutations/useCancelEvent";
import { Textarea } from "@/components/textarea";
import { useAcceptFinalOffer } from "@/core/hooks/candidate/mutations/useAcceptFinalOffer";
import { FiCheckCircle } from "react-icons/fi";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/accordion";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSendPositiveFeedback } from "@/core/hooks/candidate/mutations/useSendPositiveFeedback";
import { useGetEventConfig } from "@/core/hooks/useGetEventConfig";
import { useSaveCompanyInterviewPresence } from "@/core/hooks/candidate/mutations/useSaveCompanyInterviewPresence";
import { useRejectFinalOffer } from "@/core/hooks/candidate/mutations/useRejectFinalOffer";
import { localToUTCTime } from "@/libs/utils/timeSlots";
import { InterviewProposalForm } from "@/components/interview/interview-proposal-form";
import { InterviewTimeSelector } from "@/components/interview/interview-time-selector";
import { useSeeOffer } from "@/core/hooks/candidate/mutations/useSeeOffer";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export function formatInterviewType(type: string | undefined): string {
  return type === "offline" ? "la sediul companiei" : type === "phone" ? "Telefonic" : (type ?? "");
}

const htmlToMarkdown = (html: string) => {
  const turndownService = new TurndownService({
    headingStyle: "atx",
    codeBlockStyle: "fenced",
    emDelimiter: "_",
    strongDelimiter: "**",
  });

  // customize turndown rules if needed
  turndownService.addRule("preserveBreaks", {
    filter: ["br"],
    replacement: () => "  \n",
  });

  turndownService.addRule("strong", {
    filter: ["strong", "b"],
    replacement: content => `*${content}*`,
  });

  return turndownService.turndown(html);
};

const OfferDetailsPage: FC = () => {
  const { id } = Route.useParams();
  const { cancel, reject } = Route.useSearch();
  const navigate = Route.useNavigate();
  const token = getAngularToken();
  const queryClient = useQueryClient();

  const { data: account } = useGetAccount(token ?? "");
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showRejectOfferForm, setShowRejectOfferForm] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedTimeId, setSelectedTimeId] = useState<number | undefined>(undefined);
  const [reasonForReject, setReasonForReject] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string>("");
  const [interviewDates, setInterviewDates] = useState<Array<{ date?: Date; time: string }>>([
    { date: undefined, time: "" },
    { date: undefined, time: "" },
    { date: undefined, time: "" },
  ]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const rejectionReasons = [
    { id: "insufficient_salary", value: "Salariu insuficient" },
    { id: "location_issue", value: "Nu ma avantajeaza adresa sediului angajatorului" },
    { id: "not_interested_in_employer", value: "Nu ma intereseaza angajatorul" },
    { id: "schedule_issue", value: "Nu ma avantajeaza programul de lucru" },
    { id: "job_mismatch", value: "Descrierea/responsabilitatile jobului nu corespund cu profilul meu" },
    { id: "already_employed", value: "Mi-am gasit deja un job sau nu mai caut job in acest moment" },
    { id: "other", value: "Altul" },
  ];

  const { mutate: seeOffer } = useSeeOffer();
  const { data: offer, isLoading: isOfferLoading } = useGetOfferByProcessId(true, Number(id));
  const { mutate: acceptOffer, isPending: isAcceptingOffer } = useAcceptOffer();
  const { mutate: acceptOfferFastTrack } = useAcceptOfferFastTrack();
  const { mutate: rejectOffer, isPending: isRejectingOffer } = useRejectOffer();
  const { mutate: acceptFinalOffer, isPending: isAcceptingFinalOffer } = useAcceptFinalOffer();
  const { mutate: sendPositiveFeedback, isPending: isSendingPositiveFeedback } = useSendPositiveFeedback();
  const { mutate: saveCompanyInterviewPresence, isPending: isSavingCompanyInterviewPresence } =
    useSaveCompanyInterviewPresence();
  const { mutate: rejectFinalOffer, isPending: isRejectingFinalOffer } = useRejectFinalOffer();

  useDocumentTitle(`Oferta ${offer?.need.company.name}`);

  const { data: availability } = useGetAvailability({
    processId: String(offer?.need.id ?? 0),
    shouldFetch: !!offer?.need.id,
  });

  const { data: eventConfig, isLoading: isLoadingEventConfig } = useGetEventConfig();

  const { data: blockedSlots, isLoading: isLoadingBlockedSlots } = useGetEvents({
    participants_id: offer?.need.company.user_id ?? 0,
    status: "accepted",
    content_type: eventConfig?.[0]?.content_type,
    shouldFetch: !!offer?.id,
  });

  console.log("blockedSlots", blockedSlots);

  const { data: candidateBlockedSlots, isLoading: isLoadingCandidateBlockedSlots } = useGetEvents({
    participants_id: account?.user.id ?? 0,
    status: "accepted",
    content_type: eventConfig?.[0]?.content_type,
    shouldFetch: !!offer?.id,
  });

  console.log("candidateBlockedSlots", candidateBlockedSlots);

  const combinedBlockedSlots = [...(candidateBlockedSlots ?? []), ...(blockedSlots ?? [])];

  const { data: proposedEvents, isLoading: isLoadingProposedEvents } = useGetEvents({
    status: "proposed",
    content_type: eventConfig?.[0]?.content_type,
    process_id: Number(offer?.id),
    shouldFetch: !!offer?.id,
  });

  const { data: acceptedEvents, isLoading: isLoadingAcceptedEvents } = useGetEvents({
    status: "accepted",
    content_type: eventConfig?.[0]?.content_type,
    process_id: Number(offer?.id),
    shouldFetch: !!offer?.id,
  });

  const { data: cancelledEvents, isLoading: isLoadingCancelledEvents } = useGetEvents({
    status: "canceled",
    content_type: eventConfig?.[0]?.content_type,
    process_id: Number(offer?.id),
    shouldFetch: !!offer?.id,
  });

  const { data: rejectedEvents, isLoading: isLoadingRejectedEvents } = useGetEvents({
    status: "rejected",
    content_type: eventConfig?.[0]?.content_type,
    process_id: Number(offer?.id),
    shouldFetch: !!offer?.id,
  });

  const { mutateAsync: acceptProposedTime, isPending: isAcceptingProposedTime } = useAcceptProposedTime();
  const { mutateAsync: rejectProposedTime, isPending: isRejectingProposedTime } = useRejectProposedTime();
  const { mutateAsync: cancelEvent, isPending: isCancellingEvent } = useCancelEvent();

  const eventProposedByCandidate = proposedEvents?.find(event => event.requester === account?.user.id);
  const eventProposedByCompany = proposedEvents?.find(event => event.requester === offer?.need.company.user_id);
  const acceptedEvent = acceptedEvents?.find(
    event =>
      event.participants.includes(account?.user.id ?? 0) &&
      event.participants.includes(offer?.need.company.user_id ?? 0)
  );

  useEffect(() => {
    seeOffer({ offerId: id });
  }, []);

  const isProposedByCompany = !!eventProposedByCompany;
  const isProposedByCandidate = !!eventProposedByCandidate;

  const rejectedEvent = rejectedEvents?.[0];

  const cancelledEvent = cancelledEvents?.[0] ?? null;

  const eventCancelledByCompany = cancelledEvent
    ? cancelledEvent?.status_updated_by === offer?.need.company.user_id
    : false;

  const eventRejectedByCompany = rejectedEvents?.[0]?.status_updated_by === offer?.need.company.user_id;
  const eventRejectedByCandidate = rejectedEvents?.[0]?.status_updated_by === account?.user.id;

  const latestRejectedEventByCandidate = rejectedEvents
    ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .find(event => event.status_updated_by === account?.user.id);

  const showProposeForm =
    // only show if there are no cancelled events
    !cancelledEvent &&
    // aND one of these conditions is true:
    ((!proposedEvents?.length && !rejectedEvent) ||
      (!!latestRejectedEventByCandidate && !isProposedByCompany && !acceptedEvent) ||
      isProposedByCandidate ||
      eventRejectedByCompany ||
      !isProposedByCompany);

  const showProposedTimeForm = isProposedByCompany && !isProposedByCandidate && !acceptedEvent;

  const latestInterview = offer?.interviews.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

  // const allFinishedInterviews = offer?.interviews.filter(interview => interview.finished === 1);

  const hasFollowUpInterview = offer?.events.some(event => event.type === "FOLLOWUP_INTERVIEW_REQUESTED");

  const latestEvent = offer?.events.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

  const latestCandidateFeedback = offer?.events.find(event => event.type === "CANDIDATE_INTERVIEW_POSITIVE");

  const hasCandidatePositiveFeedback = offer?.events.some(event => {
    if (!latestCandidateFeedback) {
      return false;
    }

    if (
      event.type !== "CANDIDATE_INTERVIEW_POSITIVE" ||
      !event.metadata ||
      (JSON.parse(event.metadata) as ApiProcessEventMetadata).interview_id !== latestInterview?.id
    ) {
      return false;
    }

    return (
      (latestEvent?.type === "CANDIDATE_INTERVIEW_POSITIVE" &&
        (JSON.parse(event.metadata) as ApiProcessEventMetadata).interview_id === latestInterview?.id) ||
      (latestEvent?.type === "FOLLOWUP_INTERVIEW_REQUESTED" &&
        new Date(latestEvent.updated_at) > new Date(event.updated_at)) ||
      (latestEvent?.type === "INTERVIEW_PRESENT" && new Date(latestEvent.updated_at) > new Date(event.updated_at))
    );
  });

  const isFirstInterviewPhase = latestEvent?.type === "COMPANY_ACCEPTED";

  const firstInterviewMeta = useMemo(() => {
    try {
      const event = offer?.events.find(event => event.type === "COMPANY_ACCEPTED");

      if (!event?.metadata) {
        return null;
      }
      return JSON.parse(event.metadata) as ApiProcessEventMetadata;
    } catch (error) {
      console.error("Error parsing interview metadata:", error);
      return null;
    }
  }, [offer?.events]);

  const latestRejectedEvent = rejectedEvents?.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

  const status = useGetProcessStatus(offer);

  const { mutateAsync: proposeInterview, isPending: isProposing } = useProposeInterviewTime();

  const handleAcceptProposedTime = async () => {
    if (!selectedTimeId) {
      toast.error("Te rugam sa selectezi o optiune");
      return;
    }

    await acceptProposedTime(
      {
        event_id: eventProposedByCompany?.id ?? 0,
        proposed_time_id: selectedTimeId ?? 0,
      },
      {
        onSuccess: async () => {
          toast.success("Propunerea a fost acceptata");
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
        },
        onError: async () => {
          toast.error("A apărut o eroare la acceptarea propunerii");
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "proposed"] });
        },
      }
    );
  };

  const handleRejectProposedTime = async () => {
    await rejectProposedTime(
      {
        event_id: acceptedEvent?.id ?? eventProposedByCompany?.id ?? 0,
      },
      {
        onSuccess: async () => {
          toast.success("Propunerea a fost respinsa");
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
        },
        onError: error => {
          toast.error(error.message);
        },
      }
    );
  };

  const handleAcceptOfferFastTrack = () => {
    if (offer?.fast_track) {
      acceptOfferFastTrack(
        { offerId: offer?.id.toString() },
        {
          onSuccess: async () => {
            toast.success("Oferta a fost acceptata cu succes!");
            await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
          },
          onError: () => {
            toast.error("A apărut o eroare la acceptarea ofertei");
          },
        }
      );
    } else {
      setShowFeedbackForm(true);
    }
  };

  const handleAcceptOffer = () => {
    if (date) {
      acceptOffer(
        { offerId: offer?.id.toString() ?? "", date },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
            toast.success("Oferta a fost acceptata cu succes!");
            setShowFeedbackForm(false);
          },
          onError: () => {
            toast.error("A apărut o eroare la acceptarea ofertei.");
          },
        }
      );
    }
  };

  const handleAcceptFinalOffer = () => {
    acceptFinalOffer(
      { offer_id: offer?.id.toString() ?? "" },
      {
        onSuccess: async () => {
          toast.success("Oferta a fost acceptata cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
        },
      }
    );
  };

  const handleRejectFinalOffer = () => {
    rejectFinalOffer(
      { offer_id: offer?.id.toString() ?? "", reason: reasonForReject.join(",") },
      {
        onSuccess: async () => {
          toast.success("Oferta a fost respinsa cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
          await navigate({ search: {} });
        },
      }
    );
  };

  const handleCancelEvent = async () => {
    await cancelEvent(
      {
        event_id: acceptedEvent?.id ?? 0,
        reason: otherReason ? otherReason : reasonForReject.join(","),
      },
      {
        onSuccess: async () => {
          toast.success("Interviu a fost anulat");
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
          await navigate({ search: {} });
        },
        onError: error => {
          toast.error(error.message);
        },
      }
    );
  };

  const handleRejectOffer = () => {
    rejectOffer(
      { offerId: offer?.id.toString() ?? "", note: reasonForReject.join(", ") },
      {
        onSuccess: async () => {
          toast.success("Oferta a fost respinsa cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
        },
        onError: error => {
          toast.error(error.message);
        },
      }
    );
  };

  const handleSendPositiveFeedback = () => {
    sendPositiveFeedback(
      { interview_id: offer?.interviews[0]?.id ?? 0 },
      {
        onSuccess: async () => {
          toast.success("Feedback a fost trimis cu succes!");

          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
        },
        onError: error => {
          toast.error(error.message);
        },
      }
    );
  };

  const handleSaveCompanyInterviewPresence = (isPresent: boolean) => {
    saveCompanyInterviewPresence(
      {
        interviewId: latestInterview?.id.toString() ?? "",
        isCompanyPresent: isPresent,
      },
      {
        onSuccess: async () => {
          toast.success("Prezența a fost salvată cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
        },
        onError: error => {
          toast.error(error.message);
        },
      }
    );
  };

  const generateTimeSlots = () => {
    const slots = [];
    const startHour = 9;
    const endHour = 17;

    // get user's local timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    for (let hour = startHour; hour < endHour; hour++) {
      const now = new Date();
      const romanianTime = new TZDate(now, "Europe/Bucharest");
      const localTime = new TZDate(now, userTimezone);

      const hourDiff = romanianTime.getHours() - localTime.getHours();

      const localHour = hour - hourDiff;
      const localNextHour = localHour + 1;

      // skip slots that would fall outside reasonable hours (e.g., middle of the night)
      if (localHour < 0 || localHour > 23) {
        continue;
      }

      const formattedStart = Math.floor(localHour).toString().padStart(2, "0");
      const formattedEnd = Math.floor(localNextHour).toString().padStart(2, "0");

      slots.push({
        value: `${hour.toString().padStart(2, "0")}:00-${(hour + 1).toString().padStart(2, "0")}:00`,
        label: `${formattedStart}:00 - ${formattedEnd}:00`,
      });
    }

    return slots;
  };

  // inside your component where you handle time slots:
  const generateTimeOptions = (selectedDate?: Date) => {
    if (!selectedDate || !availability || !blockedSlots || !eventConfig) {
      return [];
    }

    const durationMinutes = getDurationInMinutes(eventConfig[0]?.duration);
    const bufferMinutes = getDurationInMinutes(eventConfig[0]?.buffer_before);

    const availableSlots = generateAvailableTimeSlots(
      selectedDate,
      availability?.intervals,
      combinedBlockedSlots,
      durationMinutes,
      bufferMinutes
    );

    return availableSlots.map(slot => ({
      value: `${format(slot.start, "HH:mm")}-${format(slot.end, "HH:mm")}`,
      label: `${format(slot.start, "HH:mm")} - ${format(slot.end, "HH:mm")}`,
    }));
  };

  const handleProposeTime = async () => {
    const proposedTimes = interviewDates
      .filter(slot => slot.date && slot.time)
      .map(slot => {
        const [startTime] = slot.time.split("-");
        const durationMinutes = getDurationInMinutes(eventConfig?.[0]?.duration ?? "30");
        const startDate = localToUTCTime(slot.date!, startTime);

        const endDate = new Date(startDate.getTime() + durationMinutes * 60 * 1000);

        return {
          start_time: startDate.toISOString(),
          end_time: endDate.toISOString(),
          status: "proposed" as const,
        };
      });

    const interviewMeta = hasFollowUpInterview
      ? {
          interview_type: latestRejectedEvent?.metadata?.interview_type,
          address: latestRejectedEvent?.metadata?.address,
          contact_person: latestRejectedEvent?.metadata?.contact_person,
          phone: latestRejectedEvent?.metadata?.phone,
        }
      : {
          interview_type: firstInterviewMeta?.interview_type,
          address: firstInterviewMeta?.address,
          contact_person: firstInterviewMeta?.contact_person,
          phone: firstInterviewMeta?.phone,
        };

    await proposeInterview(
      {
        title: `Interview with ${offer?.need.company.name}`,
        description: "",
        event_type: 1,
        participants: [offer?.need.company.user_id ?? 0, account?.user.id ?? 0],
        status: "proposed",
        requester: account?.user.id ?? 0,
        content_type: eventConfig?.[0]?.content_type as number,
        object_id: offer?.id ?? 0,
        related_object: null,
        location: offer?.interview_type as InterviewLocation,
        location_details: "",
        proposed_times: proposedTimes,
        metadata: interviewMeta,
      },
      {
        onSuccess: async () => {
          toast.success("Propunerile au fost trimise cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", offer?.id, "canceled"] });
        },
        onError: error => {
          toast.error(error.message);
        },
      }
    );
  };

  if (
    isOfferLoading ||
    isLoadingBlockedSlots ||
    isLoadingProposedEvents ||
    isLoadingAcceptedEvents ||
    isLoadingRejectedEvents ||
    isLoadingCandidateBlockedSlots ||
    isLoadingCancelledEvents ||
    isLoadingEventConfig
  ) {
    return <Loading />;
  }

  const showInterviewFeedbackForm =
    !proposedEvents?.length &&
    (status === ProcessStatus.FollowUpInterviewRequested ||
      status === ProcessStatus.CompanyInterviewPresent ||
      status === ProcessStatus.CandidateInterviewPresent ||
      status === ProcessStatus.CompanyInterviewMissed ||
      status === ProcessStatus.CandidateInterviewMissed) &&
    !hasCandidatePositiveFeedback;

  const hasFeedback =
    status === ProcessStatus.CompanyInterviewMissed ||
    status === ProcessStatus.CandidateInterviewMissed ||
    status === ProcessStatus.CompanyInterviewPresent ||
    status === ProcessStatus.CandidateInterviewPresent;

  const oneIsNotPresent =
    status === ProcessStatus.CompanyInterviewMissed || status === ProcessStatus.CandidateInterviewMissed;

  const bothArePresent =
    status === ProcessStatus.CompanyInterviewPresent || status === ProcessStatus.CandidateInterviewPresent;

  const isArchived = offer?.need.archived;

  const isValidated = offer?.valid;

  return (
    <div className="flex max-sm:flex-col gap-5 h-screen mt-5 md:mt-10 pb-10">
      <div className="bg-white p-4 md:p-6 md:overflow-hidden w-full md:w-4/6 order-2 md:order-none rounded-lg md:h-max">
        <div className="flex flex-col h-full">
          <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-6">
            <Avatar className={cn("size-16 md:size-20", offer?.need.company.logo ? "bg-transparent" : "")}>
              <AvatarImage src={`${import.meta.env.VITE_AWS_S3_BUCKET}/${offer?.need.company.logo}`} />
              <AvatarFallback>{offer?.need.company.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <div>
              <Typography className="text-xl md:text-2xl font-bold text-blue-dark capitalize">
                {offer?.need.company.name}
              </Typography>
              {offer?.need.company.description && (
                <Typography className="text-base md:text-lg text-gray-700">
                  {offer?.need.company.description}
                </Typography>
              )}
            </div>
          </div>

          <div className="mt-6 md:mt-8">
            <Typography className="text-gray-400 text-base md:text-lg">Despre angajator</Typography>

            <ReactMarkdown
              components={{
                // customize how different markdown elements are rendered
                p: ({ children }) => <Typography className="text-gray-600 text-lg mb-4">{children}</Typography>,
                h1: ({ children }) => <Typography className="text-xl font-bold mb-4">{children}</Typography>,
                h2: ({ children }) => <Typography className="text-lg font-bold mb-3">{children}</Typography>,
                ul: ({ children }) => (
                  <ul className="list-disc ml-8 mb-4 marker:text-gray-600 marker:text-lg">{children}</ul>
                ),
                ol: ({ children }) => <ol className="list-decimal ml-8 mb-4">{children}</ol>,
                li: ({ children }) => <li className="mb-2 text-gray-500 marker:text-lg">{children}</li>,
                strong: ({ children }) => <span className="font-bold text-gray-700">{children}</span>,
                em: ({ children }) => <span className="font-bold">{children}</span>,
              }}
            >
              {htmlToMarkdown(offer?.need.description || "")}
            </ReactMarkdown>
          </div>

          <div className="mt-8 md:mt-10 mb-4 md:mb-6">
            <Typography className="text-gray-600 text-xl md:text-2xl font-semibold">OFERTA</Typography>
            <Typography className="text-gray-900 text-base md:text-lg font-semibold">
              {offer?.need.category.value}
            </Typography>
            <Typography className="text-gray-800 font-light text-base md:text-lg capitalize">
              {offer?.need.address ? `${offer?.need.address}, ` : ""}
              {offer?.need.city.name} ({offer?.need.city.county})
            </Typography>
          </div>

          <div className="flex flex-col gap-3 md:gap-2">
            <div className="flex flex-col md:flex-row md:items-start gap-1 md:gap-2 text-gray-600">
              <Typography className="text-gray-400 text-base md:text-lg md:min-w-44">Salariu full-time:</Typography>
              <div className="flex gap-2 items-center">
                <Typography className="text-gray-600 text-base md:text-lg">{offer?.need.salary}</Typography>
                {offer?.need.salary_to && (
                  <Typography className="text-gray-600 text-base md:text-lg">- {offer?.need.salary_to}</Typography>
                )}
                <Typography className="text-gray-600 text-base md:text-lg">LEI</Typography>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-start gap-1 md:gap-2 text-gray-600">
              <Typography className="text-gray-400 text-base md:text-lg md:min-w-44">Program:</Typography>
              <div>
                <Typography className="text-gray-600 text-base md:text-lg">
                  {offer?.need.schedules.map(schedule => schedule.value).join(", ")}
                </Typography>
              </div>
            </div>
            {offer?.need.experiences && offer?.need.experiences.length > 0 ? (
              <div className="flex flex-col md:flex-row md:items-start gap-1 md:gap-2 text-gray-600">
                <Typography className="text-gray-400 text-base md:text-lg md:min-w-44">Experienta:</Typography>
                <div>
                  <Typography className="text-gray-600 text-base md:text-lg">
                    {offer?.need.experiences.map(experience => experience.value).join(", ")}
                  </Typography>
                </div>
              </div>
            ) : null}

            {offer?.need.collaborations && offer?.need.collaborations.length > 0 ? (
              <div className="flex flex-col md:flex-row md:items-start gap-1 md:gap-2 text-gray-600">
                <Typography className="text-gray-400 text-base md:text-lg md:min-w-44">Forma de colaborare:</Typography>
                <div>
                  <Typography className="text-gray-600 text-base md:text-lg">
                    {offer?.need.collaborations.map(collaboration => collaboration.value).join("\n")}
                  </Typography>
                </div>
              </div>
            ) : null}

            {offer?.need.benefits && offer?.need.benefits.length > 0 ? (
              <div className="flex flex-col md:flex-row md:items-start gap-1 md:gap-2 text-gray-600">
                <Typography className="text-gray-400 text-base md:text-lg md:min-w-44">Beneficii:</Typography>
                <div>
                  <Typography className="text-gray-600 text-base md:text-lg">
                    {offer?.need.benefits.map(benefit => benefit.value).join(", ")}
                  </Typography>
                </div>
              </div>
            ) : null}

            {offer?.need.requests && offer?.need.requests.length > 0 ? (
              <div className="flex flex-col md:flex-row md:items-start gap-1 md:gap-2 text-gray-600">
                <Typography className="text-gray-400 text-base md:text-lg md:min-w-44">Alte cerinte:</Typography>
                <div>
                  <Typography className="text-gray-600 text-base md:text-lg">
                    {offer?.need.requests.map(request => request.value).join(", ")}
                  </Typography>
                </div>
              </div>
            ) : null}

            {offer?.need.responsabilities ? (
              <div className="flex flex-col md:flex-row md:items-start gap-1 md:gap-2 text-gray-600">
                <div
                  className="text-gray-600 text-base md:text-lg"
                  dangerouslySetInnerHTML={{
                    __html: offer?.need.responsabilities || "",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {
        <div className="md:max-w-96 max-sm:w-full max-sm:order-1">
          {status === ProcessStatus.OfferAcceptedByCandidate && (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max max-sm:w-full max-sm:order-1">
              <div className="flex flex-col gap-4">
                <Typography className="text-lg">
                  Un reprezentant din echipa noastra te va suna in ziua si ora alese de tine.
                </Typography>
                <Typography className="text-lg">
                  Ziua:{" "}
                  <b>
                    {offer?.validation_interview_date &&
                      format(new Date(offer.validation_interview_date), "MMMM d, yyyy", { locale: ro })}
                  </b>
                  . Interval orar:{" "}
                  <b>
                    (
                    {offer?.validation_interview_date &&
                      format(new TZDate(new Date(offer.validation_interview_date), "Europe/Bucharest"), "HH:mm", {
                        locale: ro,
                      })}{" "}
                    -{" "}
                    {offer?.validation_interview_date &&
                      format(
                        new TZDate(
                          new Date(
                            new Date(offer.validation_interview_date).setHours(
                              new Date(offer.validation_interview_date).getHours() + 1
                            )
                          ),
                          "Europe/Bucharest"
                        ),
                        "HH:mm",
                        { locale: ro }
                      )}
                    )
                  </b>
                </Typography>
              </div>
            </div>
          )}

          {status === ProcessStatus.New ? (
            <>
              {isArchived ? (
                <div className="md:max-w-96 max-sm:w-full max-sm:order-1">
                  <div className="flex-1 bg-white p-6 rounded-lg shadow">
                    <Typography className="text-lg font-semibold">Oferta nu mai este disponibila</Typography>
                    <Typography className="text-gray-600">
                      Postul a fost ocupat, de aceea aceasta oferta nu mai este disponibila. Cand ti-ai actualizat
                      profilul Medijobs ultima data?
                    </Typography>
                    <Link to="/profile" className="text-blue-dark underline text-medi-blue">
                      Actualizeaza-ti profilul!
                    </Link>
                    <br />
                    <em className="text-gray-600">
                      Raspunde la oferte in maxim 48h pentru a-ti creste sansele de angajare!
                    </em>
                  </div>
                </div>
              ) : (
                <div className="flex-1 bg-white p-6 rounded-lg shadow">
                  {!showFeedbackForm && !showRejectOfferForm && (
                    <div className="flex flex-col gap-4">
                      <Typography className="text-lg">Te intereseaza aceasta oferta de munca?</Typography>
                      <div className="flex gap-2">
                        <Button onClick={handleAcceptOfferFastTrack} variant="default" className="w-full">
                          Ma Intereseaza
                        </Button>
                        <Button onClick={() => setShowRejectOfferForm(true)} variant="destructive" className="w-full">
                          Nu ma intereseaza
                        </Button>
                      </div>
                    </div>
                  )}
                  {showFeedbackForm && (
                    <div className="flex flex-col gap-4">
                      <Typography className="text-gray-600 text-lg font-light">
                        Vrem sa-ti oferim mai multe detalii despre oferta de angajare. Selecteaza ziua si ora la care te
                        putem suna pentru un scurt interviu telefonic de 10 minute.
                      </Typography>

                      <Typography className="text-gray-900 text-lg font-semibold">
                        Data si intervalul orar la care te putem contacta:
                      </Typography>

                      <div className="flex gap-2">
                        <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                          <PopoverTrigger className="w-full shadow-md">
                            <Button variant="outline" className="w-full justify-start text-gray-400">
                              <LuCalendar className="size-4" />
                              {date ? format(date, "MMMM d, yyyy", { locale: ro }) : "Selecteaza ziua"}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="bg-white w-max">
                            <Calendar
                              mode="single"
                              selected={date}
                              onSelect={date => {
                                setDate(date);
                                setIsCalendarOpen(false);
                              }}
                              className="rounded-md border"
                              disabled={
                                date =>
                                  date < new Date() ||
                                  date.getDay() === 0 || // disable Sundays
                                  date.getDay() === 6 || // disable Saturdays
                                  isHolidayDate(date) // disable holidays
                              }
                            />
                          </PopoverContent>
                        </Popover>
                        {date && (
                          <Select
                            disabled={!date}
                            onValueChange={value => {
                              const [startHour] = value.split("-")[0].split(":");
                              const newDate = new Date(date);

                              newDate.setHours(parseInt(startHour), 0, 0, 0);
                              setDate(newDate);
                              setIsButtonDisabled(false);
                            }}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Selecteaza ora" />
                            </SelectTrigger>
                            <SelectContent>
                              {generateTimeSlots().map(slot => (
                                <SelectItem key={slot.value} value={slot.value}>
                                  {slot.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      </div>

                      <Button
                        onClick={handleAcceptOffer}
                        variant="default"
                        loading={isAcceptingOffer}
                        disabled={isButtonDisabled}
                      >
                        Confirma disponibilitatea
                      </Button>
                    </div>
                  )}
                  {showRejectOfferForm && (
                    <div className="flex flex-col gap-4">
                      <Typography className="text-gray-600 text-lg font-light">
                        Te rugam sa ne ajuti cu motivul refuzului tau, pentru a iti trimite oferte mai relevante pe
                        viitor.
                      </Typography>

                      <div className="flex flex-col gap-6">
                        {rejectionReasons.map(reason => (
                          <div className="flex items-center gap-2" key={reason.id}>
                            <Checkbox
                              checked={reasonForReject.includes(reason.id)}
                              onCheckedChange={value =>
                                setReasonForReject(
                                  value
                                    ? [...reasonForReject, reason.id]
                                    : reasonForReject.filter(id => id !== reason.id)
                                )
                              }
                            />
                            <Label className="text-gray-600 text-lg font-light">{reason.value}</Label>
                          </div>
                        ))}
                      </div>

                      <Button onClick={() => setShowRejectOfferForm(false)} variant="outline" className="w-full">
                        Inapoi
                      </Button>
                      <Button
                        onClick={handleRejectOffer}
                        loading={isRejectingOffer}
                        disabled={reasonForReject.length === 0}
                        variant="destructive"
                      >
                        Respinge oferta
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : null}

          {status === ProcessStatus.OfferRejectedByCandidate && (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max">
              {isFirstInterviewPhase ? (
                <div className="flex flex-col gap-4">
                  <Typography className="text-lg font-semibold">Interviu anulat</Typography>
                  <Typography className="text-gray-600">
                    Ai solicitat anularea interviului din data de{" "}
                    {latestRejectedEvent?.start_time &&
                      format(new Date(latestRejectedEvent.start_time), "dd MMM, yyyy", { locale: ro })}{" "}
                    cu {offer?.need.company.name}. Oferta a fost mutata in Arhiva.
                  </Typography>
                </div>
              ) : (
                <>
                  {hasFeedback ? (
                    <div className="flex flex-col gap-4">
                      <Typography className="text-lg font-semibold">Proces abandonat de candidat</Typography>
                      <Typography className="text-gray-600">
                        Ai abandonat procesul de recrutare pentru postul de {offer?.need.category.value} dupa
                        participarea la interviul {latestRejectedEvent?.metadata?.interview_type} cu{" "}
                        {offer?.need.company.name} din data de{" "}
                        {latestRejectedEvent?.start_time &&
                          format(new Date(latestRejectedEvent.start_time), "dd MMM, yyyy", { locale: ro })}
                        . Oferta a fost mutata in Arhiva.
                      </Typography>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-4">
                      <Typography className="text-lg font-semibold">Interviu anulat</Typography>
                      <Typography className="text-gray-600">
                        Ai anulat interviul pentru postul de {offer?.need.category.value} la {offer?.need.company.name}.
                        Oferta a fost mutata in Arhiva. Recomandarea Medijobs este ca anularea interviurilor sa se faca
                        cu cel putin 48h inainte.
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {status === ProcessStatus.InterviewAcceptedByMediJobs && (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max">
              <div className="flex flex-col gap-4">
                <Typography className="text-lg font-semibold">
                  Am trimis profilul tau catre {offer?.need.company.name}
                </Typography>
                <Typography className="text-gray-600">
                  Te vom anunta printr-o notificare daca angajatorul doreste sa programeze un interviu cu tine.
                </Typography>
              </div>
            </div>
          )}

          {status === ProcessStatus.InterviewRejectedByMediJobs && (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max">
              <div className="flex flex-col gap-4">
                <Typography className="text-lg font-semibold">Ai fost invalidat pentru această poziție</Typography>
              </div>
            </div>
          )}

          {status === ProcessStatus.InterviewAcceptedByCompany ? (
            <>
              {showProposedTimeForm && (
                <InterviewTimeSelector
                  proposedTimes={eventProposedByCompany?.proposed_times ?? []}
                  selectedTimeId={selectedTimeId}
                  onTimeSelect={setSelectedTimeId}
                  onAccept={handleAcceptProposedTime}
                  onReject={handleRejectProposedTime}
                  isLoading={isAcceptingProposedTime || isRejectingProposedTime}
                  title="Programeaza interviu"
                  entityName={offer?.need.company.name}
                />
              )}

              {showProposeForm && !isProposedByCompany && !acceptedEvent ? (
                <InterviewProposalForm
                  entityName={offer?.need.company.name}
                  interviewType={
                    hasFollowUpInterview
                      ? (formatInterviewType(latestRejectedEvent?.metadata?.interview_type) as
                          | "offline"
                          | "phone"
                          | "virtual")
                      : (formatInterviewType(offer?.interview_type) as "offline" | "phone" | "virtual")
                  }
                  availability={availability}
                  interviewDates={interviewDates}
                  setInterviewDates={setInterviewDates}
                  generateTimeOptions={generateTimeOptions}
                  onPropose={handleProposeTime}
                  onReject={async () => navigate({ search: { cancel: "true" } })}
                  isProposing={isProposing}
                  eventProposedByCandidate={!!eventProposedByCandidate}
                  eventRejectedByCompany={!!eventRejectedByCompany}
                  eventRejectedByCandidate={!!latestRejectedEventByCandidate}
                  rejectedEvent={latestRejectedEventByCandidate}
                />
              ) : null}

              {eventCancelledByCompany ? (
                <div className="flex-1 bg-white p-3 rounded-lg shadow h-max">
                  <div className="flex flex-col gap-4">
                    <Typography className="text-gray-600 text-lg">
                      De data aceasta, profilul tau nu s-a potrivit cu ceea ce cauta compania {offer?.need.company.name}{" "}
                      insa vom reveni cat de curand cu noi oferte de angajare pentru tine!
                    </Typography>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

          {status === ProcessStatus.InterviewRejectedByCompany ? (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max">
              <div className="flex flex-col gap-4">
                <Typography className="text-lg font-semibold">Oferta nu mai este disponibila</Typography>
                <Typography className="text-gray-600">
                  {isValidated ? (
                    <>
                      Compania a refuzat programarea unui interviu. Nu iti face griji!{" "}
                      <Link to="/profile" className="text-medi-blue inline-block hover:no-underline font-semibold">
                        Actualizeaza-ti profilul
                      </Link>{" "}
                      cat timp noi te conectam cu oferte noi de angajare!
                    </>
                  ) : (
                    <>
                      Ce s-a intamplat? Rolul a fost acoperit sau profilul tau nu a corespuns cu unul din criteriile de
                      selectie ale companiei. Nu iti face griji! Te conectam cat de curand cu alte oportunitati mai
                      potrivite!
                    </>
                  )}
                </Typography>
              </div>
            </div>
          ) : null}

          {status === ProcessStatus.InterviewScheduled && latestInterview ? (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max space-y-2">
              <div className="flex justify-between items-center gap-4">
                <Typography className="text-lg font-bold">Detalii interviu</Typography>
                <span className="bg-green-50 text-green-600 px-2 py-1 rounded-md">Acceptat</span>
              </div>
              <Typography className="text-gray-600 md:text-lg">
                Felicitari! Ai programat interviul pentru <b>{offer?.need.category.value}</b> la{" "}
                <b>{offer?.need.company.name}</b>.
              </Typography>
              <div className="flex flex-col gap-2">
                <Typography className="text-gray-600 md:text-lg capitalize">
                  Tip interviu:{" "}
                  <b>
                    {formatInterviewType(
                      hasFollowUpInterview
                        ? latestInterview?.phone
                          ? "phone"
                          : latestInterview?.address
                            ? "offline"
                            : "virtual"
                        : offer?.interview_type
                    )}
                  </b>
                </Typography>
                {latestInterview.address && (
                  <Typography className="text-gray-600 md:text-lg">
                    Adresa sediului: <b>{latestInterview.address}</b>
                  </Typography>
                )}
                {latestInterview?.contact_person && (
                  <Typography className="text-gray-600 md:text-lg">
                    Persoana de contact: <b>{latestInterview.contact_person}</b>
                  </Typography>
                )}
                {latestInterview?.phone && (
                  <Typography className="text-gray-600 md:text-lg">
                    Telefon: <b>{latestInterview.phone}</b>
                  </Typography>
                )}
              </div>
              <Typography className="text-gray-800 md:text-lg font-semibold">Ziua si ora interviului tau</Typography>
              <div className="flex bg-medi-blue/5 p-2 rounded-lg gap-2">
                <div className="flex flex-col flex-1">
                  <div className="flex items-center justify-between">
                    <Typography className="text-gray-600 md:text-sm">
                      <b>{format(new Date(latestInterview.scheduled_date_time), "dd MMM, yyyy")}</b>
                    </Typography>
                    <Typography className="text-gray-600 md:text-sm">
                      <b>{format(new Date(latestInterview.scheduled_date_time), "HH:mm")}</b>
                    </Typography>
                  </div>
                  <div className="flex items-center justify-between font-light">
                    <Typography className="text-gray-500 md:text-sm capitalize">
                      <b>{format(new Date(latestInterview.scheduled_date_time), "EEEE", { locale: ro })}</b>
                    </Typography>
                    <Typography className="text-gray-500 md:text-sm">
                      <b>
                        {format(
                          new Date(
                            new Date(latestInterview.scheduled_date_time).getTime() +
                              getDurationInMinutes(eventConfig?.[0]?.duration ?? "30") * 60 * 1000
                          ),
                          "HH:mm"
                        )}
                      </b>
                    </Typography>
                  </div>
                </div>
                <div className="flex flex-col flex-1 pl-2 border-l-2 border-medi-blue">
                  <Button
                    onClick={handleRejectProposedTime}
                    loading={isRejectingProposedTime}
                    variant="outline"
                    className="w-full bg-white border-medi-blue text-medi-blue h-10"
                  >
                    Reprogrameaza
                  </Button>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <Typography className="text-gray-600">
                  Daca nu poti participa la interviu, te rugam sa anulezi acest interviu.
                </Typography>
                <Button onClick={async () => navigate({ search: { cancel: true } })} variant="destructive">
                  Anuleaza interviu
                  <LuX className="size-4" />
                </Button>
              </div>
            </div>
          ) : null}

          {status === ProcessStatus.InterviewRescheduled ? (
            <>
              {eventRejectedByCandidate || !isProposedByCompany ? (
                <InterviewProposalForm
                  eventProposedByCandidate={!!eventProposedByCandidate}
                  eventRejectedByCompany={!!eventRejectedByCompany}
                  eventRejectedByCandidate={!!latestRejectedEventByCandidate}
                  isProposing={isProposing}
                  onPropose={handleProposeTime}
                  onReject={async () => navigate({ search: { cancel: "true" } })}
                  interviewType={offer?.interview_type as "offline" | "virtual" | "phone"}
                  interviewDates={interviewDates}
                  setInterviewDates={setInterviewDates}
                  generateTimeOptions={generateTimeOptions}
                  availability={availability}
                  rejectedEvent={latestRejectedEventByCandidate}
                />
              ) : (
                <div className="flex-1 bg-white p-4 md:p-6 rounded-lg shadow h-max">
                  <div className="flex flex-col gap-4">
                    <Typography className="text-gray-600 md:text-lg">
                      Compania {offer?.need.company?.name} a propus următoarele intervale pentru interviu. Te rugăm să
                      selectezi una dintre aceste opțiuni pentru programarea interviului.
                    </Typography>

                    <div className="flex items-center gap-2">
                      <Typography className="text-gray-500 md:text-lg ">Tipul interviului:</Typography>
                      <Typography className="text-gray-800 md:text-lg font-semibold capitalize">
                        {formatInterviewType(offer?.interview_type)}
                      </Typography>
                    </div>
                    <Typography className="text-gray-800 md:text-lg font-semibold">Programeaza interviu</Typography>
                    <Typography className="text-gray-500 md:text-base">
                      Mai jos sunt optiunile propuse de {offer?.need.company?.name}. Te rugam sa alegi una dintre
                      optiuni sau sa propui o alta zi si ora mai convenabila:
                    </Typography>
                    <div className="relative">
                      <Typography className="font-semibold">Alege una dintre optiuni:</Typography>

                      <div className="flex flex-col gap-2">
                        {eventProposedByCompany?.proposed_times.map((slot, index) => (
                          <button
                            onClick={() => setSelectedTimeId(slot.id)}
                            key={index}
                            className={cn(
                              "border rounded-md px-3 py-2 w-full",
                              selectedTimeId === slot.id && "border-medi-blue bg-medi-blue/10"
                            )}
                          >
                            <div className="flex items-center gap-3 justify-between">
                              <span className="">
                                {format(new Date(slot.start_time), "dd MMM. yyyy", { locale: ro })}
                              </span>
                              <span>{format(new Date(slot.start_time), "HH:mm")}</span>
                            </div>
                            <div className="flex items-center justify-between text-gray-400">
                              <span className="">{format(new Date(slot.start_time), "EEEE", { locale: ro })}</span>
                              <span>{format(new Date(slot.end_time), "HH:mm")}</span>
                            </div>
                          </button>
                        ))}
                      </div>

                      <div className="flex gap-2 mt-4">
                        <Button
                          variant="default"
                          className="flex-1"
                          loading={isAcceptingProposedTime}
                          disabled={!selectedTimeId || isAcceptingProposedTime}
                          onClick={handleAcceptProposedTime}
                        >
                          Programeaza interviu
                        </Button>
                        <Button
                          variant="outline"
                          className="flex-1"
                          onClick={handleRejectProposedTime}
                          loading={isRejectingProposedTime}
                        >
                          Seteaza alte date
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}

          {status === ProcessStatus.InterviewPassed ? (
            <div className="bg-white p-6 rounded-lg h-max space-y-4 w-full">
              <Typography className="text-gray-800 text-lg font-semibold">
                A fost prezent reprezentantul companiei {offer?.need.company.name} la interviu?
              </Typography>

              <div className="flex gap-2">
                <Button
                  variant="default"
                  className="flex-1"
                  onClick={() => {
                    handleSaveCompanyInterviewPresence(true);
                  }}
                  disabled={isSavingCompanyInterviewPresence}
                >
                  Da, a fost prezent
                </Button>
                <Button
                  variant="destructive"
                  className="flex-1"
                  onClick={() => {
                    handleSaveCompanyInterviewPresence(false);
                  }}
                  disabled={isSavingCompanyInterviewPresence}
                >
                  Nu a fost prezent
                </Button>
              </div>
            </div>
          ) : null}

          {showInterviewFeedbackForm && (
            <div className="bg-white p-6 rounded-lg h-max space-y-4 w-full">
              {bothArePresent && (
                <div className="space-y-2">
                  <Typography className="text-gray-800 text-lg font-semibold">Cum a decurs interviul?</Typography>
                  <Typography className="text-gray-600">
                    Ai discutat cu <b>{offer?.need.company.name}</b>. Ce decizie ai luat?
                  </Typography>
                </div>
              )}

              {oneIsNotPresent && (
                <div className="space-y-2">
                  <Typography className="text-gray-800 text-lg font-semibold">
                    Doresti sa continui acest proces de recrutare si sa reprogramezi interviul?
                  </Typography>
                  <Typography className="text-gray-600">Selecteaza una din optiunile de mai jos</Typography>
                </div>
              )}

              <Accordion type="single" collapsible className="w-full space-y-2" defaultValue="continue">
                {bothArePresent ? (
                  <AccordionItem value="continue" className="border rounded-md data-[state=open]:bg-medi-blue/10">
                    <AccordionTrigger className="w-full hover:no-underline justify-start gap-2" showChevron={false}>
                      <Label className="py-2 cursor-pointer flex gap-2">
                        <FiCheckCircle className="size-4 text-green-500" /> Voi continua procesul de angajare
                      </Label>
                    </AccordionTrigger>

                    <AccordionContent className="px-4 pb-4">
                      <Button
                        onClick={handleSendPositiveFeedback}
                        variant="default"
                        size="sm"
                        className="w-full"
                        loading={isSendingPositiveFeedback}
                      >
                        Vreau sa continui procesul de angajare
                      </Button>
                    </AccordionContent>
                  </AccordionItem>
                ) : null}

                {oneIsNotPresent ? (
                  <AccordionItem value="reschedule" className="border rounded-md data-[state=open]:bg-medi-blue/10">
                    <AccordionTrigger className="w-full hover:no-underline justify-start gap-2" showChevron={false}>
                      <Label className="py-2 cursor-pointer flex gap-2">
                        <LuCircleGauge className="size-4 text-medi-blue" /> Vreau sa reprogramez interviul
                      </Label>
                    </AccordionTrigger>

                    <AccordionContent className="px-4 pb-4">
                      <div className="space-y-2">
                        <Button
                          onClick={handleRejectProposedTime}
                          loading={isRejectingProposedTime}
                          variant="outline"
                          className="w-full bg-white border-medi-blue text-medi-blue h-10"
                        >
                          Reprogrameaza
                        </Button>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ) : null}

                <AccordionItem value="discontinue" className="border rounded-md data-[state=open]:bg-medi-blue/10">
                  <AccordionTrigger className="w-full hover:no-underline justify-start gap-2" showChevron={false}>
                    <Label className="py-2 cursor-pointer flex gap-2">
                      <RiCloseCircleLine className="size-4 text-red-500" /> Nu voi continua procesul
                    </Label>
                  </AccordionTrigger>

                  <AccordionContent className="px-4 pb-4">
                    <div className="space-y-2">
                      <Select onValueChange={value => setReasonForReject([value])} value={reasonForReject[0]}>
                        <SelectTrigger>
                          <SelectValue placeholder="Selecteaza un motiv" />
                        </SelectTrigger>
                        <SelectContent>
                          {rejectionReasons.map(reason => (
                            <SelectItem key={reason.id} value={reason.id}>
                              {reason.value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      {reasonForReject.find(id => id === "other") && (
                        <Textarea
                          placeholder="Motivul anularii"
                          value={otherReason}
                          onChange={e => setOtherReason(e.target.value)}
                        />
                      )}

                      <Button
                        onClick={handleCancelEvent}
                        disabled={!reasonForReject.length}
                        variant="destructive"
                        size="sm"
                        className="w-full"
                        loading={isCancellingEvent}
                      >
                        Nu vreau sa continui procesul
                      </Button>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          )}

          {hasCandidatePositiveFeedback && !proposedEvents?.length ? (
            <div className="bg-white p-6 rounded-lg h-max space-y-4 w-full">
              <Typography className="text-gray-800 text-lg font-semibold">
                Multumim pentru feedback! Raspunsul tau a fost transmis angajatorului.
              </Typography>

              <div className="space-y-3">
                <Typography className="text-gray-600 text-base font-normal">
                  Ce urmeaza? Te anuntam de indata ce angajatorul ia o decizie in privinta candidaturii tale
                </Typography>
              </div>
            </div>
          ) : null}
          {status === ProcessStatus.FollowUpInterviewRequested ? (
            <>
              {proposedEvents?.length && !eventProposedByCompany ? (
                <InterviewProposalForm
                  entityName={offer?.need.company.name}
                  interviewType={offer?.interview_type as "offline" | "virtual" | "phone"}
                  availability={availability}
                  interviewDates={interviewDates}
                  setInterviewDates={setInterviewDates}
                  generateTimeOptions={generateTimeOptions}
                  onPropose={handleProposeTime}
                  onReject={async () => navigate({ search: { cancel: "true" } })}
                  isProposing={isProposing}
                  eventProposedByCandidate={!!eventProposedByCandidate}
                  eventRejectedByCompany={!!eventRejectedByCompany}
                  rejectedEvent={latestRejectedEventByCandidate}
                />
              ) : null}
              {proposedEvents?.length && eventProposedByCompany ? (
                <InterviewTimeSelector
                  proposedTimes={eventProposedByCompany?.proposed_times ?? []}
                  selectedTimeId={selectedTimeId}
                  onTimeSelect={setSelectedTimeId}
                  onAccept={handleAcceptProposedTime}
                  onReject={handleRejectProposedTime}
                  entityName={offer?.need.company.name}
                  // interviewType={offer?.interview_type as "offline" | "virtual" | "phone"}
                />
              ) : null}
            </>
          ) : null}
          {/* {status === ProcessStatus.InterviewRejectedByMediJobs && (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max">
              <div className="flex flex-col h-full">
                <Typography className="text-xl font-semibold mb-4">Detalii interviu</Typography>
                <div className="bg-gray-100 text-gray-700 text-sm px-3 py-1 rounded w-fit mb-4">INACCEPTAT</div>

                <Typography className="text-gray-600 text-sm mb-6">
                  Nu aveti inca profilul complet pentru acest post complet. IN UNE COMPANIE - invitatie deschisa pentru
                  postul de: [TITULATURA - trebuie deschisa] Expertii MEDjobs iti recomanda sa iti completezi profilul
                  pentru a-ti creste sansele de angajare pe viitor.
                </Typography>

                <Button variant="secondary" className="w-full">
                  Completeaza profil
                </Button>
              </div>
            </div>
          )} */}

          {status === ProcessStatus.Trial ? (
            <div className="flex-1 bg-white p-3 rounded-lg shadow h-max">
              <div className="flex flex-col gap-4">
                <Typography className="text-gray-600 text-lg">
                  Nu te-ai prezentat la interviul programat. Nu uita: de fiecare data cand nu te prezinti la un interviu
                  iti scade sansa de a fi chemat pe viitor la unele noi
                </Typography>
              </div>
            </div>
          ) : null}
          <Dialog open={!!cancel || !!reject} onOpenChange={async () => navigate({ search: {} })}>
            <DialogContent className="bg-white max-w-xl">
              <DialogHeader>
                <DialogTitle>
                  <Typography className="text-xl font-semibold">
                    {cancel ? "Anulare interviu" : "Respingere oferta"}
                  </Typography>
                </DialogTitle>
              </DialogHeader>

              <div className="flex flex-col gap-4">
                <Typography className="text-gray-600 text-lg font-light">
                  {cancel
                    ? "Inainte de a anula acest interviu, te rugam sa ne oferi motivul anularii. Feedback-ul tau ne va ajuta sa imbunatatim procesul."
                    : "Inainte de a respinge aceasta oferta, te rugam sa ne oferi motivul respingerii. Feedback-ul tau ne va ajuta sa imbunatatim procesul."}
                </Typography>

                <div className="flex flex-col gap-6">
                  {rejectionReasons.map(reason => (
                    <div className="flex items-center gap-2" key={reason.id}>
                      <Checkbox
                        checked={reasonForReject.includes(reason.id)}
                        onCheckedChange={value =>
                          setReasonForReject(
                            value ? [...reasonForReject, reason.id] : reasonForReject.filter(id => id !== reason.id)
                          )
                        }
                      />
                      <Label className="text-gray-600 text-lg font-light">{reason.value}</Label>
                    </div>
                  ))}

                  {reasonForReject.length > 0 && reasonForReject.find(id => id === "other") && (
                    <div className="flex flex-col gap-2">
                      <Textarea
                        placeholder="Motivul anularii"
                        value={otherReason}
                        onChange={e => setOtherReason(e.target.value)}
                      />
                    </div>
                  )}
                </div>

                <div className="flex gap-2 mt-4">
                  <Button variant="outline" className="flex-1" onClick={async () => navigate({ search: {} })}>
                    Renunta
                  </Button>
                  <Button
                    variant="destructive"
                    className="flex-1"
                    disabled={reasonForReject.length === 0}
                    loading={cancel ? isCancellingEvent : isRejectingFinalOffer}
                    onClick={cancel ? handleCancelEvent : handleRejectFinalOffer}
                  >
                    Confirma anulare
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {status === ProcessStatus.FinalOffer ? (
            <div className="bg-white p-6 rounded-lg h-max space-y-4">
              <Typography className="text-gray-600" variant="h3">
                In urma interviului, angajatorul ar vrea sa iti faca o oferta finala. Detaliile acesteia le poti vedea
                mai jos.
              </Typography>

              {offer?.offer ? (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <Typography className="text-gray-700 font-medium text-xl">Data de incepere</Typography>
                    <Typography className="text-gray-600 font-medium text-base">
                      {format(new Date(offer.offer.start_date), "dd MMMM yyyy", { locale: ro })}
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography className="text-gray-700 font-medium text-xl">Perioada contractuala</Typography>
                    <Typography className="text-gray-600 font-medium text-base">
                      {offer.offer.contract_period}
                    </Typography>
                  </div>

                  <div className="flex flex-col">
                    <Typography className="text-gray-700 font-medium text-xl">Salariu final</Typography>
                    <Typography className="text-gray-600 font-medium text-base">{offer.offer.salary} LEI</Typography>
                  </div>

                  {offer.offer.other_benefits && (
                    <div className="flex flex-col">
                      <Typography className="text-gray-700 font-medium text-xl">Alte beneficii</Typography>
                      <Typography className="text-gray-600 font-normal text-base">
                        {offer.offer.other_benefits}
                      </Typography>
                    </div>
                  )}

                  <div className="flex gap-3">
                    <Button
                      onClick={handleAcceptFinalOffer}
                      loading={isAcceptingFinalOffer}
                      variant="default"
                      className="flex-1"
                    >
                      Accepta oferta
                    </Button>
                    <Button
                      onClick={async () => navigate({ search: { reject: true } })}
                      variant="destructive"
                      className="flex-1"
                    >
                      Respinge oferta
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {status === ProcessStatus.Hire && offer ? (
            <div className="bg-white p-6 rounded-lg h-max space-y-4">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <Typography className="text-gray-700 font-medium text-xl">Data de incepere</Typography>
                  <Typography className="text-gray-600 font-medium text-base">
                    {format(new Date(offer.offer.start_date), "dd MMMM yyyy", { locale: ro })}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography className="text-gray-700 font-medium text-xl">Perioada contractuala</Typography>
                  <Typography className="text-gray-600 font-medium text-base">{offer.offer.contract_period}</Typography>
                </div>

                <div className="flex flex-col">
                  <Typography className="text-gray-700 font-medium text-xl">Salariu final</Typography>
                  <Typography className="text-gray-600 font-medium text-base">{offer.offer.salary} LEI</Typography>
                </div>

                {offer.offer.other_benefits && (
                  <div className="flex flex-col">
                    <Typography className="text-gray-700 font-medium text-xl">Alte beneficii</Typography>
                    <Typography className="text-gray-600 font-normal text-base">
                      {offer.offer.other_benefits}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      }
    </div>
  );
};

export default OfferDetailsPage;
