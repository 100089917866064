import { cn } from "@/libs/utils/cn";
import { Button } from "../button/Button";
import { Calendar } from "../calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../select/select";
import { Typography } from "../typography";
import { LuCalendar } from "react-icons/lu";
import { format } from "date-fns";
import { isDateInAvailability } from "@/core/utils";
import { useGetAvailabilityResponse } from "@/core/hooks/useGetAvailability";
import { formatInterviewType } from "@/pages/offer/candidate";
import { useState } from "react";

interface InterviewProposalFormProps {
  availability?: useGetAvailabilityResponse;
  interviewDates: Array<{ date?: Date; time: string }>;
  setInterviewDates: (dates: Array<{ date?: Date; time: string }>) => void;
  eventProposedByCandidate?: boolean;
  eventRejectedByCompany?: boolean;
  eventProposedByCompany?: boolean;
  eventRejectedByCandidate?: boolean;
  isProposing?: boolean;
  onPropose: () => void;
  onReject?: () => void;
  generateTimeOptions: (date?: Date) => Array<{ value: string; label: string }>;
  isCompany?: boolean;
  entityName?: string;
  interviewType?: "offline" | "phone" | "virtual";
  rejectedEvent?: ApiEvent;
}

export const InterviewProposalForm = ({
  availability,
  eventProposedByCandidate,
  eventRejectedByCompany,
  eventProposedByCompany,
  eventRejectedByCandidate,
  isProposing,
  onPropose,
  onReject,
  interviewDates,
  setInterviewDates,
  generateTimeOptions,
  isCompany,
  interviewType,
  rejectedEvent,
}: InterviewProposalFormProps) => {
  const isAllDatesAndTimesSet = interviewDates.every(slot => slot.date && slot.time);

  const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);

  return (
    <div className="flex-1 bg-white p-6 max-sm:p-4 rounded-lg shadow h-max md:max-w-[400px] max-sm:w-full max-sm:order-1">
      <div className="flex flex-col gap-4 max-sm:gap-3">
        {!isCompany && !eventProposedByCandidate && !eventRejectedByCompany && (
          <Typography className="text-gray-600 md:text-lg">
            <b>Felicitari, ai primit o invitatie la interviu!</b>
            <br />
            <br className="max-sm:hidden" />
            {/* <b>Compania {entityName} vrea sa te cunoasca.</b> Alege ziua si ora la care poti discuta cu reprezentantul
            lor. */}
          </Typography>
        )}

        {isCompany && (
          <div>
            <Typography className="text-gray-800 md:text-lg font-semibold">Mentiunea expertului MEDIjobs</Typography>
            <Typography className="text-gray-600">
              Este o persoană dedicată, cu o atitudine proactivă și o capacitate remarcabilă de a colabora eficient în
              echipă.
            </Typography>
          </div>
        )}

        {!eventProposedByCandidate && !eventRejectedByCompany && (
          <div className="flex items-center gap-2">
            <Typography className="text-gray-500 md:text-lg ">Tipul interviului:</Typography>
            <Typography className="text-gray-800 md:text-lg font-semibold capitalize">
              {formatInterviewType(interviewType)}
            </Typography>
          </div>
        )}

        {!isCompany && !eventProposedByCandidate && !eventRejectedByCompany && eventRejectedByCandidate && (
          <Typography className="text-gray-800 md:text-lg font-semibold">
            Compania vrea sa te cunoasca. Trimite alte 3 optiuni de zi si ora pentru programarea interviului
          </Typography>
        )}

        {!eventProposedByCandidate && !eventRejectedByCompany && (
          <Typography className="text-gray-800 md:text-lg font-semibold">Programeaza interviu</Typography>
        )}

        {!eventRejectedByCandidate && !eventRejectedByCompany && rejectedEvent && (
          <div className="border-l-4 border-red-400 bg-red-50 p-3 rounded-r-lg">
            <Typography className="text-gray-800 font-semibold mb-2">Intervale respinse anterior:</Typography>
            {rejectedEvent.proposed_times.map((time, index) => (
              <div key={index} className="text-gray-600">
                {format(new Date(time.start_time), "dd/MM/yyyy")} la ora {format(new Date(time.end_time), "HH:mm")}
              </div>
            ))}
          </div>
        )}

        <div className="relative">
          <Typography className="font-semibold max-sm:text-sm">Propune 3 optiuni:</Typography>

          {interviewDates.map((slot, index) => (
            <div key={index} className="flex flex-col gap-2">
              <div className="border rounded-lg p-4">
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Typography className="text-gray-600 md:text-lg font-semibold max-sm:text-base">
                      Data interviu
                    </Typography>
                    <Popover
                      open={openPopoverIndex === index}
                      onOpenChange={open => {
                        setOpenPopoverIndex(open ? index : null);
                      }}
                    >
                      <PopoverTrigger className="w-full shadow-md" asChild>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full justify-start text-gray-400 max-sm:text-sm max-sm:py-2",
                            slot.date && "text-gray-800"
                          )}
                        >
                          <LuCalendar className="size-4" />
                          {slot.date ? format(slot.date, "dd/MM/yyyy") : "Selecteaza data"}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="bg-white w-max">
                        <Calendar
                          mode="single"
                          selected={slot.date}
                          onSelect={date => {
                            const newDates = [...interviewDates];

                            newDates[index].date = date;
                            setInterviewDates(newDates);
                            setOpenPopoverIndex(null);
                          }}
                          className="rounded-md border"
                          disabled={
                            date =>
                              date < new Date() || // disable past dates
                              date < new Date(availability?.start_date ?? "") ||
                              date > new Date(availability?.end_date ?? "") ||
                              !isDateInAvailability(date, availability?.intervals ?? []) // disable dates not in availability
                          }
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                  <div className="flex-1">
                    <Typography className="text-gray-600 md:text-lg font-semibold max-sm:text-base">
                      Ora interviu
                    </Typography>
                    <Select
                      disabled={!slot.date}
                      value={slot.time}
                      onValueChange={value => {
                        const newDates = [...interviewDates];

                        newDates[index].time = value;
                        setInterviewDates(newDates);
                      }}
                    >
                      <SelectTrigger className="max-sm:text-sm max-sm:py-2">
                        <SelectValue placeholder="Selecteaza ora" />
                      </SelectTrigger>
                      <SelectContent>
                        {generateTimeOptions(slot.date).map(timeSlot => (
                          <SelectItem key={timeSlot.value} value={timeSlot.value}>
                            {timeSlot.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="flex gap-2 mt-4 max-sm:flex-col">
            <Button
              variant="default"
              className="flex-1 max-sm:text-sm"
              disabled={!isAllDatesAndTimesSet || isProposing}
              onClick={onPropose}
            >
              {isProposing ? "Se trimite..." : "Trimite propunerile"}
            </Button>
            <Button variant="outline" className="flex-1 max-sm:text-sm" onClick={onReject}>
              Refuza invitatia
            </Button>
          </div>
          {!isCompany ? (
            <div className="flex flex-col gap-2">
              {eventProposedByCandidate && !eventRejectedByCompany && (
                <div className="overlay absolute inset-0 bg-white/95 flex flex-col items-center justify-center">
                  <Typography className="text-gray-600 text-lg font-semibold text-center">
                    Gata! Am trimis noile propuneri pentru interviu. Te anuntam prin e-mail ce varianta a ales compania
                    sau daca a solicitat o reprogramare.
                  </Typography>
                </div>
              )}

              {eventRejectedByCompany && (
                <div className="overlay absolute inset-0 bg-white/95 flex flex-col items-center justify-center">
                  <Typography className="text-gray-600 text-lg font-semibold text-center">
                    Compania a refuzat intervalele propuse. Veti fi notificat prin email cand compania va propune noi
                    intervale pentru interviu.
                  </Typography>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {eventProposedByCompany && !eventRejectedByCandidate && (
                <div className="overlay absolute inset-0 bg-white/95 flex flex-col items-center justify-center">
                  <Typography className="text-gray-600 text-lg font-semibold text-center">
                    Gata! Am trimis noile propuneri pentru interviu. Te anuntam prin e-mail ce varianta a ales
                    candidatul sau daca a solicitat o reprogramare.
                  </Typography>
                </div>
              )}

              {eventRejectedByCandidate && (
                <div className="overlay absolute inset-0 bg-white/95 flex flex-col items-center justify-center">
                  <Typography className="text-gray-600 text-lg font-semibold text-center">
                    candidatul a refuzat intervalele propuse. Veti fi notificat prin email cand candidatul va propune
                    noi intervale pentru interviu.
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
